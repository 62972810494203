import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const CustomerServicePage = () => (
    <Layout>
        <Seo title="Services For Existing Customers | Nemo Personal Finance" description={'If you\'re an exisitng customer with Nemo Personal Finance and have any issues don\'t hesitate to contact us. Visit us online for any assistance you need.'} lang={'en-gb'} />
        <div className="threeCol purple cfx">
            <aside id="leftCol">
                <div id="tNav">
                    <ul>
                        <li><a href="/customer-service/moving-home">How your Nemo mortgage
                            works</a></li>
                        <li><a href="/customer-service/remortgaging">What to do if you want to
                            remortgage </a></li>
                        <li><a href="/customer-service/settle-my-second-charge-mortgage">Settle
                            my Nemo mortgage early</a></li>
                        <li><a href="/customer-service/update-my-details">Update my details</a>
                        </li>
                    </ul>
                </div>
            </aside>
            <section id="rightCol">
                <div className="contCol">
                    <div className="contColInr">
                        <div className="bubble purple cfx">
                            <div className="inr">
                                <h1>Customer Service</h1>
                            </div>
                            <div className="bot"></div>
                        </div>

<p>What will be the new reference rate? </p>

<p>The reference rate going forward will be the Bank of England Base Rate, and we are giving customers who are impacted by this change, 30 days’ notice.</p>

<p>How will this affect me?</p>

<p>Please rest assured that we’re committed to ensuring any transfer to the Bank of England Base Rate will not make any direct beneficial or detrimental change to Nemo or our customers. </p>

<p>How will I know if I’m affected?</p>

<p>If Clause 2.3 in your agreement refers to LIBOR, please note that the wording is now replaced with the following update:</p>

<p>“We will not increase the interest rate on your account if, at the time of the proposed increase, doing so would mean that your interest rate had increased by more than double any increase there had been in the Bank of England Base Rate published by the Bank of England Monetary Policy Committee over the previous 6 months. If this rate is not published then we will select another reference rate that we reasonably think best matches it.”</p>

<p>What if I no longer have my paperwork?</p>

<p>Please <a href="/contact-us">contact us</a> if you need further explanation. </p>

<p>Where can I find out more about the LIBOR change?</p>
<p>Please <a href="/contact-us">contact us</a> if you need any more information. Thank you for being a Nemo customer.</p>

                        <p className="leading">You might have some questions about your mortgage that we can answer
                            here. </p>
                        <ul>
                            <li>
                                <strong>Your mortgage statements<br /></strong>For more information about statements,
                                please see our <a
                                href="/faq">FAQ's</a>. &nbsp;
                            </li>
                            <li>
                                <strong>PPI</strong>&nbsp;<br />For more information about PPI, please see our&nbsp;<a
                                href="/contact-us/complaints">Complaints</a> page.&nbsp;</li>
                            <li>
                                <strong>Updating your details</strong> <br />If your personal details have changed, and
                                you need to update us, <a
                                    href="/customer-service/update-my-details">find out more</a>.
                            </li>
                        </ul>
                        <p>If you’ve got any other questions you may find the answer you are looking for in our <a
                            href="/faq">FAQ's</a>. If
                            not, please <a href="/contact-us">contact us</a>. </p>
                    </div>
                </div>
                <div className="fbCol"></div>
            </section>
        </div>
    </Layout>
)

export default CustomerServicePage;